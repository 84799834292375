.message {
  display: flex;
  position: fixed;
  top: $header-height;
  right: $margin;
  z-index: 9999;
  min-width: calc($sidebar-width + 50px);
  max-width: calc($sidebar-width + 50px);
  border-radius: $border-radius;

  @include themed {
    background-color: t('hero');
  }

  transition: $transition;
  box-shadow: $box-shadow;
  animation: $pop-animation;
  justify-content: flex-start;
  align-items: center;

  &.is-hidden {
    opacity: 0;
    height: 0;
    font-size: 0;
    padding: 0;
    margin: 0 auto;
    display: block;
  }

  .message-icon {
    padding: $padding;
    width: 60px;

    span {
      font-size: 2em;
      animation: $bell-shake-animation;
    }

    &.info {
      color: $info-color;
    }

    &.warning {
      color: $warn-color;
    }

    &.error {
      color: $error-color;
    }

    &.success {
      color: $success-color;
    }

  }

  .message-body {
    // animation: $slide-in-animation;
    text-align: center;
    padding: 1rem .5rem;

    p {
      font-size: 1em;
      font-weight: $font-weight-semibold;
    }
  }

}




.message-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;

  span {
    font-size: 1.5em;
  }
}