// .timetable {
//     background-color: $light-color;
//     width: 100%;
//     height: 85vh;
//     color: #000;
//     border-radius: 0;
//     margin: auto;

//     .body {
//         padding: $padding;
//         //    width: 50%;
//     }

//     .remove-time {
//         color: $error-color;
//         cursor: pointer;
//         margin-left: 1rem;
//         bottom: 2rem;
//     }

//     .time-selected {
//         cursor: pointer;
//         text-decoration: underline !important;
//     }
// }

// .timetable-view {
//     background-color: $light-color;
//     width: 8.3in;
//     height: 11.7in;
//     color: #000;
//     border-radius: 0;
//     overflow-y: scroll;
//     margin: auto;
//     padding: 2rem 4rem;

//     .timetable-top-header {
//         text-align: center;
//         font-size: 2rem;
//         color: $info-color;
//         font-weight: $font-weight-bold;
//     }

//     .timetable-sub-header {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         font-size: 1rem;
//         padding-bottom: 1rem;
//     }

//     .divider {
//         border-bottom: 4px solid green;
//         width: 100%;
//         margin: auto;
//     }

//     .timetable-body {
//         margin: 1rem 0;
//         position: relative;

//         table {
//             width: 100%;
//             position: relative;
//             border-collapse: collapse;
//             background-color: $light-color;

//             th {
//                 top: 0;
//                 color: $dark-color;
//                 text-align: center;
//                 font-size: $font-size;
//                 height: 50px !important;
//                 border: 1px solid #000;
//                 text-transform: capitalize;
//                 font-weight: $font-weight-bold;
//                 background-color: $light-color;
//             }

//             tr {
//                 cursor: pointer;
//                 text-align: center;
//                 vertical-align: middle;
//                 border: 1px solid #000;
//                 text-transform: capitalize;
//                 border-radius: 0 !important;
//             }

//             td {
//                 font-size: 14px;
//                 text-align: center;
//                 color: $dark-color;
//                 border: 1px solid #000;
//                 height: 45px !important;
//                 border-radius: 0 !important;
//                 font-weight: $font-weight-regular;

//             }

//             tfoot {
//                 display: table-row-group;
//             }
//         }
//     }

// }

// .study-days {
//     @include themed {
//         background-color: t("bg");
//     }

//     legend {
//         font-size: 1.2rem;
//         font-weight: bold;
//         margin-bottom: 1rem;
//     }

//     .checkbox-container {
//         margin-bottom: 0.5rem;

//         label {
//             font-size: 1rem;
//         }
//     }

//     @media (max-width: 600px) {
//         legend {
//             font-size: 1rem;
//         }

//         .checkbox-container {
//             label {
//                 font-size: 0.9rem;
//             }
//         }
//     }
// }


/* timetable.scss */

/* Define A4 size dimensions */
$A4-width: 210mm;
$A4-height: 297mm;

.timetable-container {
    width: $A4-width;
    height: $A4-height;
    overflow-x: hidden;  // Disable horizontal scrolling
    overflow-y: auto;    // Enable vertical scrolling if needed
    padding: 10mm;       // Optional padding
}

.timetable {
    width: 100%;
    border-collapse: collapse;
    font-size: 10px;    // Adjust font size to fit more content
    text-align: left;
    table-layout: fixed; // Ensure the table does not exceed container width

    th, td {
        border: 1px solid #ddd;
        padding: 4px;    // Reduce padding to save space
        text-overflow: ellipsis; // Handle overflow text
        overflow: hidden;        // Hide overflow text
        white-space: nowrap;     // Prevent text wrapping
    }

    th {
        background-color: #f2f2f2;
        color: #333;
        font-size: 10px; // Smaller font size for header
    }

    td {
        background-color: #fafafa;
        font-size: 10px; // Smaller font size for data
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;
    }

    .header {
        background-color: #4CAF50;
        color: white;
        font-size: 12px; // Slightly larger font size for the header row
    }
}

