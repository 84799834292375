[data-tooltip]:hover::before {
    position: absolute;
    z-index: 999;
    content: attr(data-tooltip);
    visibility: visible;
    min-width: 8.25rem;
    @include themed {
        color: t('text') !important;
        background-color: t("tooltip");
    }
    text-align: center;
    // text-transform: capitalize;
    border-radius: $border-radius;
    overflow: none;
    line-height: 2;
    font-size: $font-size;
    font-weight: $font-weight-semibold;
    margin-right: 190px;
    padding: 0 10px;
}