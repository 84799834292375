// BASIC
$border-radius: 0;

// HEADER
$header-height: 2.75rem;

// SIDEBAR
$sidebar-width: 16rem;

// INPUT
$input-padding: 1rem;
$input-border-radius: 0;
$input-margin: 0.3125rem;
$input-height: 3.257rem;

// BUTTON
$button-height: $input-height;
$button-padding: $input-padding;
$button-border-radius: 0;
$button-box-shadow: 0 0 0 $input-margin rgba($dark-color, 0.1);

// CARD
$card-padding: 1.3rem;
$badge-height: 1.5rem;

// CHECKBOX
$checkbox-size: 1rem;

// SCREEN WIDTH ABOVE
$small-screen-up: 37.5625rem;
$medium-screen-up: 62.0625rem;
$large-screen-up: 75.0625rem;

// SCREEN WIDTH BELOW
$small-screen: 37.5rem;
$medium-screen: 62rem;
$large-screen: 75rem;

// SCREEN CONDITIONS
$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";

// DIVIDER
$divider: 1px solid $background-color;

.wid {
    max-width: 20px;
}

.padding {
    padding-left: $card-padding;

    @include themed {
        border-bottom: t('divider')
    }

    font-size: 1.2rem;
}
