.show-on-medium-and-down {
    display: none !important;
}

.hide-on-small-only,
.hide-on-small-and-down {
    @media #{$small-and-down} {
        display: none !important;
    }
}

.hide-on-med-and-down {
    @media #{$medium-and-down} {
        display: none !important;
    }
}

.hide-on-med-and-up {
    @media #{$medium-and-up} {
        display: none !important;
    }
}

.hide-on-med-only {
    @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
        display: none !important;
    }
}

.hide-on-large-only {
    @media #{$large-and-up} {
        display: none !important;
    }
}



.hide-on-extra-large-only {
    @media #{$extra-large-and-up} {
        display: none !important;
    }
}

.show-on-extra-large {
    @media #{$extra-large-and-up} {
        display: block !important;
    }
}

.show-on-large {
    @media #{$large-and-up} {
        display: block !important;
    }
}

.show-on-medium {
    @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
        display: block !important;
    }
}

.show-on-small {
    @media #{$small-and-down} {
        display: block !important;
    }
}

.show-on-medium-and-up {
    @media #{$medium-and-up} {
        display: block !important;
    }
}

.show-on-medium-and-down {
    @media #{$medium-and-down} {
        display: flex !important;
    }
}


@media (max-width: $large-screen) {

    .main {
        margin: 1rem;
    }

    .sidebar {
        left: -$sidebar-width !important;
    }

    .appbar {
        width: 100% !important;
    }

    .floating-button-menu {
        display: block !important;
    }


    .sidebar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(8, 7, 7, 0.4);
        transition: all 0.4s;
        opacity: 0;
        visibility: hidden;
        z-index: 997;
    }


    .sidebar-overlay.active {
        opacity: 1;
        visibility: visible;
    }

    .toggle-sidebar-btn {
        display: none !important;
    }

    .toggle-sidebar .sidebar,
    .toggle-sidebar {
        left: 0 !important;
    }
}


@media (min-width: $large-screen-up) {


    #main,
    #footer {
        margin-left: $sidebar-width + 1;
        margin-right: 1rem;
    }

    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
        padding-left: 2.5rem !important;

    }

    .toggle-sidebar .floating-button-menu {
        display: block !important;
    }

    .toggle-sidebar .appbar {
        width: 100% !important;
    }

    .toggle-sidebar .sidebar,
    .toggle-sidebar {
        left: -$sidebar-width !important;
    }

    #menu-btn {
        display: none;
    }

}

