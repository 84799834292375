// MARGIN
$margin: 1.25rem;
$no-margin: 0 !important;

// PADDING
$padding: $margin;
$no-padding: $no-margin;

// SPACE
$space: .5rem;

.no-padding {
    padding: $no-padding;
}

.no-margin {
    margin: $no-margin;
}

.margin {
    margin: 1rem;
}

.pb-2{
    padding-bottom: 2rem;
}

.pb{
    padding-bottom: 2rem;
}

.pb-1{
    padding-bottom: 1rem;
}

.pt-1{
    padding-top: 1rem !important;
}

.mb-1{
    margin-bottom: 1rem;
}

.mt-1{
    margin-top: 1rem;
}

.mt1{
    margin-top: -1rem;
}


.space-between{
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}