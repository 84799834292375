.not-found {
    height: calc(95vh - $header-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    h1 {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        letter-spacing: 1rem;
        text-transform: uppercase;
        font-family: verdana;
        font-size: 6rem;
        font-weight: 700;
        @include themed {
            color: t("text");
            text-shadow: t("shadow");
        }

        @media (min-width: 768px) {
            font-size: 12rem;
        }
    }

    h2 {
        color: $label-color;
        font-size: 24px;
        font-weight: $font-weight-semibold;
        margin: 0;
        padding: 0;
    }

    p {
        @include themed {
            color: t("text");
        }
        font-size: $font-size;
        font-weight: $font-weight-semibold;
    }


}