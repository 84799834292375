.modal {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .42);
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;

    &.show {
        visibility: visible;

        .modal-body {
            animation: $slide-in-animation;
        }
    }

    .modal-body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: $border-radius;

        @include themed {
            background-color: t('hero');
            color: t("text")
        }

        padding: $card-padding 0px;
        // color: $text-color;
        width: 50%;
        margin-bottom: 10px;
        max-height: calc(100vh / 1.2);

        &#timetable {
            width: 95%;
            max-height: 100vh;
        }

        .modal-title {
            color: $label-color;
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
            padding: 0 $card-padding 1rem $card-padding;

            @include themed {
                border-bottom: t('divider');
            }
        }

        .modal-content {
            width: 100%;
            height: 100%;
            padding: 0 $card-padding 0 $card-padding;
            overflow-y: auto;
            overflow-x: hidden;

            form {
                padding-top: $card-padding;
            }

            input,
            textarea,
            label,
            .helper-text,
            a,
            table {
                animation: none;
                transition: none;
            }

        }

        .modal-footer {
            margin-top: calc($margin - 10px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0px $card-padding;

            // button {
            //     animation: none;
            //     transition: none;
            // }

            button {
                animation: none;
                transition: none;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                min-width: 64px;
                border: none;
                outline: none;
                line-height: inherit;
                user-select: none;
                -webkit-appearance: none;
                overflow: visible;
                vertical-align: middle;
                background: rgba(0, 0, 0, 0);
                color: $primary-color;

                &:hover {
                    @include themed {
                        background-color: t('t-hover');
                    }


                }

                &.submit {
                    margin-left: 8px;
                }

                &.disabled {
                    @include themed {
                        background-color: t('disabled');
                    }

                    color: $disabled-text-color;
                    cursor: not-allowed;
                    box-shadow: none;
                }

            }

        }

        .dialog-action {
            margin-top: calc($margin - 10px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            button {
                animation: none;
                transition: none;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                min-width: 64px;
                border: none;
                outline: none;
                line-height: inherit;
                user-select: none;
                -webkit-appearance: none;
                overflow: visible;
                vertical-align: middle;
                background: rgba(0, 0, 0, 0);
                color: $primary-color;

                &:hover {
                    @include themed {
                        background-color: t('t-hover');
                    }

                }

                &.delete {
                    margin-left: 8px;
                }
            }
        }

        .modal-footers {
            padding: 0 $card-padding;
            // display: flex;
            // flex-direction: row;
            align-items: center;
            text-align: center;

            // button {
            //     animation: none;
            //     transition: none;
            // }

            button {
                outline: none;
                box-sizing: border-box;
                margin: 1rem 0 0 0;
                padding: 0 $button-padding;
                box-shadow: none;
                font-size: $font-size;
                font-weight: $font-weight-bold;
                border: none;
                cursor: pointer;
                display: inline-block;
                min-width: 100px;
                height: $button-height;
                background-color: $primary-color;
                color: $light-color;
                border-radius: $button-border-radius ;
                transition: $transition;
                animation: $page-animation;

                &:hover {
                    box-shadow: none;

                    @include themed {
                        background-color: t('t-hover');
                    }
                }

                &.secondary {
                    @include themed {
                        background-color: t('bg');
                    }

                    color: $label-color;
                }

                &.disabled {
                    background-color: $disabled-background-color;
                    color: $disabled-text-color;
                    cursor: not-allowed;
                    box-shadow: none;
                }

            }

        }

        @media screen and (max-width: $large-screen) {
            width: 65%;
        }

        @media screen and (max-width: $medium-screen) {
            width: 85%;
        }

        @media screen and (max-width: $small-screen) {
            width: 95%;
        }
    }
}