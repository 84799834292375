.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    padding: $card-padding 0px;
    width: 100%;
    animation: $page-animation;
    margin-bottom: 10px;
    max-height: calc(100vh - $header-height - 16px);

    .view-profile {
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
            border-radius: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            // border: 1px solid rgba($primary-color,1);

            img {
                width: 100px;
                height: 100px;
                border-radius: 100px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .view-initials {
            background-color: $primary-color;
            color: $light-color;
            text-transform: uppercase;
            font-size: calc($title-font-size + 10px);
            font-weight: $font-weight-semibold;
            padding: 2rem;
            border-radius: 100px;
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .view-title {
            text-transform: capitalize;
            font-size: calc($font-size + 5px);
            font-weight: $font-weight-semibold;
            color: $label-color;
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                text-transform: capitalize;
            }

            i {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    .view-items {
        width: 100%;
        height: 100%;
        padding: 0 $card-padding 0 $card-padding;
        margin-top: 10px;
        overflow-y: auto;
        animation: $slide-in-animation;
        overflow: hidden;

        &:hover {
            overflow-y: auto;
        }

        &:hover::-webkit-scrollbar {
            width: 3px;
        }

        &:hover::-webkit-scrollbar-button {
            width: 1px;
        }

        &:hover::-webkit-scrollbar-track {
            @include themed {
                background-color: t('hero');
            }

            border-radius: $border-radius;
        }

        &:hover::-webkit-scrollbar-thumb {
            border-radius: $border-radius;

            @include themed {
                background-color: t('bg');
            }
        }

        .view-item {
            border-radius: $border-radius;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 65px;
            margin: 10px 0;
            padding: 0 $padding;
            // border-left: 5px solid $light-color;
            // transition: $transition;

            &:hover,
            &.active {

                // border-left: 5px solid $primary-color;
                @include themed {
                    background-color: t('bg');
                }

            }

            i {
                margin-right: 10px;
                color: $primary-color;
                font-size: $icon-font-size;
            }

            .title {
                text-transform: capitalize;
                font-size: $font-size;
                font-weight: $font-weight-regular;
                color: $primary-color;
            }
        }

        .view-detail {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 65px;
            margin: 10px 0;
            padding: 0 $padding;
            // transition: $transition;

            @include themed {
                background-color: t('bg');
            }

            border-radius: $border-radius;

            .label {
                margin-right: 10px;
                text-transform: capitalize;
                color: $label-color;
                font-weight: $font-weight-regular;
                font-size: $font-size;
            }

            .title {
                text-transform: capitalize;
                color: $label-color !important;
                font-size: $font-size;
            }
        }
    }

    .view-header {
        display: flex;
        margin: 0;
        padding: 0 1.5rem;

        @include themed {
            border-bottom: t("divider");
        }

        width: 100%;
        height: 60px;

        .title {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
            color: $primary-color;
        }
    }
}

.stamp {
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    .img {
        width: 200px;
        height: 200px;
        margin-bottom: 10px;
        // border-radius: 100px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        // border: 1px solid rgba($primary-color,1);

        img {
            width: 200px;
            height: 200px;
            // border-radius: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}