.progresses {
    display: flex;
    flex-direction: column;

    @include themed {
        background-color: t('hero');
    }

    border-radius: $border-radius;
    text-align: center;
    margin-bottom: calc($margin + 5px);
    width: 100%;

    .progress-bar-container {
        margin: $margin;

        .progress-bar-label {
            margin-bottom: 5px;
            text-transform: capitalize;

            @include themed {
                color: t('text');
            }

            font-weight: $font-weight-semibold;
            font-size: $font-size;
            // padding: 0 .8rem 1rem;
            width: 100%;
            // height: 50px;
            padding: 0 $card-padding 1rem 0;
        }

        .progress-bar {
            position: relative;

            @include themed {
                background-color: t('bg');
            }

            border-radius: 10px;
            overflow: hidden;

            .progress {
                background-color: #007bff;
                height: 30px;
                border-radius: 10px 0 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include themed {
                    color: t('hero');
                }

                position: relative;
                transition: width 0.5s ease-in-out;
            }

        }

        .progress-value {
            font-size: $font-size;
            white-space: nowrap;

            @include themed {
                background-color: t('hero');
                color: t("text");
            }

            padding: .39rem;
        }

        .progress-percentage {
            white-space: nowrap;

            @include themed {
                background-color: t('hero');
            }

            padding: .39rem;
        }
    }
}