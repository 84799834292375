.analyse {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    width: 100%;
    padding: $card-padding 0px;
    animation: $page-animation;
    margin-bottom: calc($margin + 5px);

    .analysis {
        display: flex;
        flex-direction: column;

        .tabs {
            display: flex;
            flex-direction: row;
            transition: $transition;
            scrollbar-width: thin;
            scrollbar-color: $icon-color transparent;
            font-size: $font-size;
            font-weight: $font-weight-semibold;
            transition: $transition;
            text-transform: capitalize;

            @include themed {
                color: t("sidebar-link-color");
            }

            @media (max-width: 768px) {
                border-right: none;
                margin-right: 0;
                flex-direction: row;
                width: 100%;
                overflow-x: scroll;

                .tab {
                    margin-bottom: 0 !important;
                    width: 100%;
                }


            }


            .tab {
                padding: 15px 15px $padding;
                margin: 2px;
                cursor: pointer;
                border-radius: $border-radius;

                &:hover {
                    background-color: $primary-color;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    color: $light-color;
                }

                &.active {
                    background-color: $primary-color;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    color: $light-color;
                }
            }
        }

        .tab-content {
            flex: 1;
        }
    }
}

.analys {
    display: flex;
    flex-direction: column;
    transition: $transition;
    padding: $card-padding 0px;

    .analysi {
        transition: $transition;
        // padding: 0 $card-padding 0 $card-padding;
    }
}

.horizontal {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    .item {
        margin: 1px;
        padding: $padding ;

        @include themed {
            background-color: t('bg');
        }

        height: calc($header-height + 10px) !important;
        cursor: pointer;

        &:hover {
            background-color: $primary-color;
            color: $light-color;
        }

        &.active {
            background-color: $primary-color;
            color: $light-color;
        }
    }
}

.item-body {
    margin: 1rem 0;
}