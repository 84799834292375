.calendar-container {
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-regular;
    font-family: $font-family;

    @include themed {
        background-color: t('hero');
    }

    border-radius: $border-radius;
    text-align: center;
    margin-bottom: calc($margin + 5px);
    width: 100%;
    padding: $card-padding 0;

    .calendar-title {
        text-align: left;

        @include themed {
            color: t('text');
            border-bottom: t("divider");
        }

        font-size: $title-font-size;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        padding: 0 $card-padding 1rem $card-padding;
        font-family: $font-family;
    }

    .calendar-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 $card-padding;

        @include themed {
            color: t("text");
        }

        button {
            @include themed {
                background: t('hero') !important;
                box-shadow: t("calender-btn-shadow");
                color: t("text");
            }

            transition: none;
            border: none !important;
            min-width: 1px !important;
            padding: 0 !important;
            border-radius: 12px;
            width: 2.5rem;
            height: 2.5rem;
            // border-radius: 50%;
            // clip-path: circle(50%);
            // border: 1px solid #999;
            // box-shadow:  0 0 0.625rem rgba($dark-color, 0.2);
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
            margin-bottom: 10px;

            &:hover {
                color: $light-color;
                background-color: rgba($primary-color, 0.8) !important;
            }
        }

        h3 {
            margin: 0;
            // padding: 0 !important;
            padding: 1px;
        }
    }

    .datepicker-calendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap: 1rem;
        padding: 0 .7rem;
        font-family: $font-family;



        .day {
            @include themed {
                color: t("text");
            }

            font-size: .875em;
            font-weight: $font-weight-light;
            justify-self: center;
        }

        .date {
            border: 0;
            padding: 0;
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            font-weight: $font-weight-regular;
            border: 2px solid transparent;
            cursor: pointer;
            justify-self: center;

            @include themed {
                color: t("text");
            }

            &:focus {
                outline: 0;
                color: $primary-color;
                border: 2px solid $info-color;
            }

            &.faded {
                @include themed {
                    color: t("faded");
                }
            }

            &.current-day {
                color: $info-color;
                border-color: $info-color;

                &:focus {
                    background-color: $info-color;
                }
            }
        }
    }
}