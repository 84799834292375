.news-container {
    width: 100%;
    height: 270px;

    @include themed {
        background-color: t('hero');
    }

    box-sizing: border-box;
    padding: 0 $padding;
    margin-bottom: calc($margin + 5px);
    border-radius: $border-radius;
    animation: $page-animation;
    overflow: hidden;

    &:hover {
        /* or whatever height you want */
        overflow-y: auto;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    /* add styles for the news */
    .news-header {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: space-between;
        padding: $padding;
        border-bottom: $divider;

        @include themed {
            background-color: t('hero');
            color: t("text");
        }

        z-index: 1;

        .news-top-title {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
        }

        .more {}

    }


    .news {
        padding: 10px;

        &.news-item {
            margin-bottom: 10px;

            .news-name {
                font-size: $title-font-size;
            }

            .news-title {
                font-weight: $font-weight-semibold;
                font-size: 18px;
                margin-bottom: 5px;
                text-align: center;
            }

            .news-description {
                font-size: $font-size;
            }
        }

    }
}

.news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    padding: $card-padding 0px;
    width: 100%;
    animation: $page-animation;

    .news-box-header {
        padding: 0 $card-padding;

        @include themed {
            border-bottom: t('divider');
        }


        .m-t-md {
            text-align: center;

            h3,
            h4,
            h5 {
                margin-top: 5px;
                font-weight: $font-weight-semibold;
                color: $primary-color;

                span {
                    display: inline-block;
                    width: 90%;
                    font-weight: $font-weight-light;
                    color: $primary-color;

                }

                .title {
                    font-size: $title-font-size;
                    text-transform: uppercase;


                    // @include themed {
                    //     color: t("text");
                    // }
                }
            }
        }
    }

    .news-box {
        padding: $card-padding $card-padding 0 $card-padding;

        .news-body {
            border-top: 0;
        }

        .news-attachment {
            @include themed {
                border-top: t('divider');
            }

            font-size: 14px;
            padding: $card-padding 0;

            p {
                margin: 0 0 10px;
            }

            .attachment {
                .file-box {
                    float: left;
                    width: 220px;

                    .file {

                        // border: 1px solid #e7eaec;
                        @include themed {
                            border: t('divider');
                            background-color: t("hero");
                        }

                        padding: 0;
                        // background-color: $light-color;
                        position: relative;
                        margin-bottom: 16px;
                        margin-right: 16px;

                        .icon {
                            padding: 15px 10px;
                            text-align: center;
                            cursor: pointer;

                            i {
                                font-size: 70px;
                                color: $disabled-text-color;

                                &:hover {
                                    color: rgba($disabled-text-color, .7);
                                }
                            }

                        }

                        .file-name {
                            padding: 10px;

                            @include themed {
                                border-top: t('divider');
                                background-color: t("bg");
                            }

                            display: flex;
                            flex-direction: column;

                            small {
                                color: #676a6c;
                            }
                        }
                    }
                }
            }
        }
    }
}

// dashboard news 
.dashboard-news {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    padding: $card-padding 0px;
    width: 100%;
    animation: $page-animation;
    margin-bottom: calc($margin + 5px);
    // max-height: calc(60vh - $header-height - 87px);
    height: 480px;
    overflow: auto;
    cursor: pointer;

    .dashboard-news-header {
        font-size: $title-font-size;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        padding: 0 $card-padding 1rem $card-padding;
        font-family: $font-family;

        @include themed {
            border-bottom: t("divider");
        }
    }

    .dashboard-news-container {
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
        animation: $page-animation;
        overflow: auto;

        ::-webkit-scrollbar {
            display: none;
        }

        .dashboard-news-body {
            margin: $margin 0;
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: auto;
            animation: $slide-in-animation;
            padding: 0 $card-padding;

            .content {
                display: flex;
                flex-direction: column;
                padding: 10px 0;
                position: relative;
                align-items: flex-start; // Align flex items to the start of the flex container
                justify-content: space-between;

                .content-title {
                    text-transform: uppercase;
                    font-size: 1rem;
                    font-weight: $font-weight-semibold;
                    padding: 5px;

                }

                &:hover {
                    @include themed {
                        background-color: t('bg');
                    }
                }

                .read-more {
                    position: absolute;
                    padding-top: 1rem;
                    bottom: 0;
                    right: 0;
                    // padding: 5px;
                    font-size: 0.8rem;
                    font-weight: $font-weight-semibold;
                    color: $primary-color; // Change the color to match your design
                    cursor: pointer;
                }
            }

        }

    }
}