.wrapper {
    -webkit-animation: slideIn 1s;
    animation: slideIn 1s;
    // transition: all .3s ease-in-out;
    height: 100%;

    @include themed {
        background-color: t('bg');
    }

    .banner {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 90vh;
        text-align: center;

        .heading {
            padding: 1rem;
            font-weight: $font-weight-bold;
            font-family: $font-family;
            font-size: 5rem;

            @include themed {
                color: t('text');
            }

            text-shadow: -3px 2px 3px #999999;
        }

        @include themed {
            background-color: t('bg');
        }

        width: 100%;
    }

    .Waves_root__pQF2s {
        // margin-bottom: -2rem;
        overflow: hidden;
        width: 100%;

        .Waves_waves__VMmit {
            @include themed {
                background-color: t('hero');
                fill: t("bg");
            }

            width: 150%;
            height: calc(2.5rem * 2);
            display: block;
            filter: (10px 5px 5px rgba(0, 0, 0, .03));
        }
    }

    .feature-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        font-family: $font-family;

        // transition: all .5s ease;
        @include themed {
            background-color: t('bg');
        }

        .container {
            text-align: left;
        }

        p {
            font-weight: 300;
            // font-size: 1.2rem;
            font-family: $font-family;
        }

        .description {
            display: flex;
            margin: 0 2rem;
            min-height: 400px;
            flex-direction: column;
            justify-content: center;

            p {
                font-weight: 300;
                // font-size: 1.2rem;
                font-family: $font-family;

                @include themed {
                    color: t('text');
                }

            }
        }

        ul {
            list-style: none;
            display: flex;
        }

        &.reveal {
            position: relative;
            transform: translateY(50px);
            opacity: 0;
            transition: all .5s ease;

            &.active {
                transform: translateY(0px);
                opacity: 1;
            }
        }

        &.second {
            @include themed {
                transition: all .5s ease;
                background-color: t('hero') !important;
            }

            min-height: 400px;
            text-align: center;

            h1 {
                @include themed {
                    color: t('title');
                }

                font-family: $font-family;
                font-size: 3rem !important;

                @media #{$small-and-down} {
                    font-size: 3rem !important;
                }
            }


        }

        .title {
            @include themed {
                color: t('title');
            }

            font-family: $font-family;
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            padding: 1rem 0;
        }

        .feature {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: 1rem 0;


            &:hover {
                transition: 0.5s;
                transform: translateY(-10px);
            }

            @include themed {
                background-color: t('hero');
            }

            border-radius: $border-radius;
            padding: $card-padding;
            text-align: center;

            .icon {
                cursor: pointer;

                i {
                    color: $primary-color;
                    font-size: 4em;
                }
            }

            .right-section {
                text-align: center;

                .title {
                    @include themed {
                        color: t('title');
                    }

                    font-family: $font-family;
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-align: left;
                    text-decoration: underline;
                    text-align: center;
                    cursor: pointer;
                }

                .desc {
                    @include themed {
                        color: t('text');
                    }

                    font-family: $font-family;
                    font-size: 14px;
                    cursor: pointer;
                }
            }

        }
    }

    .footer {
        position: relative;
        width: 100%;

        @include themed {
            background-color: t('hero');
        }

        min-height: 100px;
        color: $light-color;
        font-family: $font-family;
        font-size: 14px;
        font-weight: 200;
        // padding: 2rem 2rem 0;
        width: 100%;

        .container {
            padding-top: 1rem;
            width: 90%;
        }

        .divider {
            border: .1px solid rgba(177, 176, 176, 0.4);
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .footer-col {
                width: 25%;
                padding: 0 5px;

                h4 {
                    font-size: 18px;

                    @include themed {
                        color: t('text');
                    }

                    text-transform: capitalize;
                    margin-bottom: 35px;
                    font-weight: 500;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        background-color: rgb(19, 106, 236);
                        height: 2px;
                        box-sizing: border-box;
                        width: 50px;
                    }
                }

                ul {
                    // padding-left: .1rem;
                    list-style: none;

                    li {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        a {
                            font-size: 16px;
                            text-transform: capitalize;
                            text-decoration: none;
                            font-weight: 300;
                            color: gray;
                            display: block;
                            transition: all 0.3s ease;
                            max-width: fit-content;

                            &:hover {
                                @include themed {
                                    color: t('text');
                                }

                                padding-left: 8px;
                                transition: 0.5s;
                            }
                        }
                    }
                }
            }
        }

        .sections {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0;

            @include themed {
                color: t('text');
            }

            .copyright {
                margin-left: 0.5rem;
            }

            .legal-links {
                // margin-top: 1rem;

                a {
                    color: $light-color;
                    margin: 0 0.5rem;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }


        .social-icon {

            position: relative;
            display: flex;
            margin: 10px 0;
            flex-wrap: wrap;

            .social-icon__item {
                list-style: none;

                .social-icon__link {
                    font-size: 2rem;
                    margin: 0 5px;
                    display: inline-block;
                    transition: 0.5s;

                    &.facebook {
                        color: rgb(24, 119, 242);
                    }

                    &.twitter {
                        color: rgb(3, 169, 244);
                    }

                    &.in {
                        color: rgb(0, 119, 181);
                    }

                    &.insta {
                        color: #833ab4 !important;
                        // background: linear-gradient(to right, #833ab4,#fd1d1d,#fcb045) !important;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent !important;
                    }

                    // color: blue;

                    &:hover {
                        transform: translateY(-10px);
                    }
                }
            }
        }

    }
}

@media #{medium-only} {
    .footer-col {
        width: 50% !important;
        padding: 0 10px;
        box-sizing: border-box;
        /* Ensure padding doesn't affect width */
        margin-bottom: 1rem;
        /* Add margin at the bottom to separate columns */
    }
}

@media #{$small-and-down} {
    .heading {
        font-size: 4rem !important;
    }

    .footer-col {
        width: 100% !important;
        padding: 0;
        box-sizing: border-box;
        /* Ensure padding doesn't affect width */
        margin-bottom: 1rem;
        /* Add margin at the bottom to separate columns */
    }

    .sections {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media only screen and (min-width: 62.0625rem) {
    .container {
        width: 95%;
    }
}

@media screen and (max-width: 62rem) {
    .banner {
        height: calc(100vh - 120px);
    }
}

@media screen and (max-width: 62rem) {
    .banner .container {
        justify-content: flex-end !important;
        padding-bottom: calc(1.5rem + 10px);
    }
}

@media only screen and (min-width: 37.5625rem) {
    .container {
        width: 95%;
    }
}


/*responsive*/
@media(max-width: 767px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media(max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}


@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}


.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url("https://i.ibb.co/wQZVxxk/wave.png");
    background-size: 1000px 100px;

    &#wave1 {
        z-index: 1000;
        opacity: 1;
        bottom: 0;
        animation: animateWaves 4s linear infinite;
    }

    &#wave2 {
        z-index: 999;
        opacity: 0.5;
        bottom: 10px;
        animation: animate 4s linear infinite !important;
    }

    &#wave3 {
        z-index: 1000;
        opacity: 0.2;
        bottom: 15px;
        animation: animateWaves 3s linear infinite;
    }

    &#wave4 {
        z-index: 999;
        opacity: 0.7;
        bottom: 20px;
        animation: animate 3s linear infinite;
    }
}