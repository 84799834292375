.tip {
    padding: 2rem 1rem;
    // border-left: 4px $primary-color solid;
    border-radius: $border-radius;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    color: black;

    .top {
        .material-icons-round {
            font-size: 1.5rem;
            color: $primary-color;
        }
    }

    @include themed {
        background-color: t("tip");
    }


}

.excel-header {
    display: flex;
    border-radius: $border-radius;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    color: black;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 $card-padding 0 $card-padding;
    animation: $slide-in-animation;
    z-index: 5;
    text-align: center;
    overflow: hidden;

    .icon {
        .material-icons-round {
            font-size: 5rem;
            color: $primary-color;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        justify-content: center;
        text-align: center;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media #{$small-and-down} {
                flex-direction: column;
            }

            p {
                margin: 0;
                font-family: $font-family;
                font-weight: $font-weight-semibold;
                color: black;
            }

            .material-icons-outlined {
                color: $primary-color;
                transform: rotate(180deg);
                animation: moveUpDown 1s infinite alternate;
            }

            .download {
                color: $primary-color;
            }
        }

        .sample {
            padding: 1rem;

            a {
                color: $primary-color;
                cursor: pointer;
                text-decoration: underline;
                padding: 0 .5rem;
            }

            i {
                cursor: pointer;
            }
        }
    }

    @media #{$small-and-down} {
        flex-direction: column;
    }

    @include themed {
        background-color: t("tip");
    }
}

.drag-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-drop-area {
    position: relative;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: $border-radius;

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }
}

.remove-file-btn {
    position: absolute;
    top: -15px;
    right: -35px;
    border: none;
    background: transparent;
    cursor: pointer;

    .close-icon {
        color: #ff0000;
    }
}

.file-input {
    display: none;
}

.center {
    text-align: center;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0) rotate(180deg);
    }

    100% {
        transform: translateY(-10px) rotate(180deg);
    }
}