/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=text],
input[type=file],
input[type=number],
input[type=email],
input[type=password],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=search],
input[type=tel],
input[type=url],
input[type=time],
input[type=month],
select,
textarea {
  border: none;
  border-radius: $input-border-radius;
  color: $label-color;
  outline: none;
  font-size: $font-size;
  font-weight: $font-weight-regular;
  margin: $input-margin 0px;
  padding: $input-padding;
  // transition: $transition;

  width: 100%;
  min-width: 100% !important;
  height: $input-height;
  animation: $page-animation;
  cursor: pointer;

  &:focus {
    @include themed {
      background-color: t('bgf');
    }
  }

  &::placeholder {
    color: $label-color;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    text-transform: none !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $label-color;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: $label-color;

    &::placeholder {
      color: $label-color;
    }

    @include themed {
      box-shadow: t('autofill');
    }

  }

  @include themed {
    background-color: t('bg');
  }

}

/* Additional styles for select options container */
select {

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    color: $label-color;

    @include themed {
      background-color: t('bg');
    }
  }

  option {
    @include themed {
      background-color: t('bg');
    }
  }

}



.helper-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 12px;
  color: $error-color;
  font-weight: $font-weight-regular;
  margin-top: 5px;
  animation: $page-animation;
  transition: $transition;
}


::-webkit-file-upload-button {
  display: none;
}

::file-selector-button {
  display: none;
}

select {
  text-transform: capitalize;

  option {
    background-color: $background-color;
    color: $label-color;
    font-size: $font-size;
    font-weight: $font-weight-semibold;
    text-transform: capitalize;
  }
}

textarea {
  width: 100%;
  min-height: 150px;
  resize: none;
}

// e876
.filter {
  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: rgb(107, 105, 105);

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}

.select {
  background-color: $background-color;
}


.check-container {
  display: flex;
  gap: 1rem;
  padding-top: $input-padding;
  align-items: center;
}

/* Global CSS or style sheet */
.red-asterisk {
  color: red;
}

.responsive-textarea {
  min-height: 270px;
}


input[type='checkbox'] {
  /* Hide the default checkbox appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  /* Set the size of the checkbox */
  width: 20px;
  height: 20px;

  /* Set the position of the checkbox */
  position: relative;
  display: inline-block;
  vertical-align: middle;
  /* Add some padding to the checkbox */
  padding: 2px;
  /* Add a border radius to the checkbox */
  // border-radius: 4px;ff

  &#toggle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 45px !important;
    height: 23px !important;
    border-radius: 30px;
    border: none !important;
    background-color: #333;
    position: relative !important;
    transition: all 0.2s ease-in !important;
    cursor: pointer;
    z-index: 1 !important;
    outline: none !important;
    padding: 0 !important;
  }

  &#toggle::before {
    content: "" !important;
    position: absolute !important;
    width: 23px !important;
    height: 23px !important;
    border-radius: 50%;
    background: #eeeded !important;
    // top: 2px !important;
    // left: 3px !important;
    // transition: all 0.2s ease-in;
  }

  &#toggle:checked {
    background: #eeeeee !important;
  }

  &#toggle:checked::before {
    background: #333 !important;
    left: 22px !important;
  }

  &#toggle:checked::after {
    content: '' !important;
    // font-size: 14px;
    // color: $light-color;
    // position: absolute;
    // top: 2.5px !important;
    // left: 3px !important;
    // transform: translate(-50%, -50%);
  }

  /* Set the border and background of the checkbox */
  @include themed {
    background-color: t('hero');
    border: t('divider');
  }

}

/* Style the checkbox when it is checked */
input[type='checkbox']:checked {
  background-color: rgba($primary-color, 1) !important;
  border-color: $primary-color !important;
}

/* Style the checkmark inside the checkbox */
input[type='checkbox']:checked:after {
  content: '\2714';
  font-size: 14px;
  color: $light-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.drag-container {
  margin: 1rem;

  :hover {
    opacity: .5;
    transition: .5s;
  }
}


.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-left: 10px;
    cursor: pointer;
  }

  [type=checkbox] {
    cursor: pointer;
    height: $checkbox-size;
    width: $checkbox-size;
    border-radius: $input-border-radius;
    outline: none;
    border: none;

    &:checked {
      accent-color: $primary-color;
      height: $checkbox-size;
      width: $checkbox-size;
      // animation: $pop-animation;
    }
  }
}