.message-tabs {
    display: flex;
    padding: 0 $card-padding;

    .tabs {
        display: flex;
        font-size: $font-size;
        transition: $transition;
        text-transform: capitalize;
        padding-bottom: $padding;
        font-weight: $font-weight-regular;

        .tab {
            padding: $padding;
            margin: 1px;
            cursor: pointer;

            &:hover {
                background-color: $primary-color;
                color: $light-color;
            }

            &.active {
                background-color: $primary-color;
                color: $light-color;
            }
        }
    }
}