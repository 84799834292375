.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transform: translate(-50%, -50%);
    // background-color: rgba($background-color, .7);
    background: rgba(0, 0, 0, .32);
    z-index: 999;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity .4s cubic-bezier(.25, .8, .25, 1);

    &.show {
        visibility: visible;

        .dialog-content {
            animation: $page-animation;
        }
    }

    .dialog-content {
        @include themed {
            background-color: t('hero');
            color: t("text");
        }

        ;
        padding: 30px 26px;
        display: block;
        border-radius: 0; // 28px
        width: calc($sidebar-width + 50px);
        border-width: 0;
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3),
        0 4px 8px 3px rgba(60, 64, 67, .15);

        @media #{$medium-and-up} {
            width: calc($sidebar-width + 250px) !important;
        }

        .dialog-title {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            margin-bottom: calc($margin - 10px);
            color: $label-color;
            text-transform: capitalize;
        }

        .dialog-text {
            font-size: $font-size;
        }

        .dialog-body {
            position: relative;
            pointer-events: none;
        }

        .dialog-action {
            margin-top: calc($margin - 10px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            button {
                animation: none;
                transition: none;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                min-width: 64px;
                border: none;
                outline: none;
                line-height: inherit;
                user-select: none;
                -webkit-appearance: none;
                overflow: visible;
                vertical-align: middle;
                background: rgba(0, 0, 0, 0);
                color: $primary-color;

                &:hover {
                    @include themed {
                        background-color: t('t-hover');
                    }

                    
                }

                &.delete {
                    margin-left: 8px;
                }
            }
        }
    }


}