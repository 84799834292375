.badge-stat {
    width: 100%;
    min-height: 135px;

    @include themed {
        background-color: t('hero');
    }

    box-sizing: border-box;
    padding: $padding;
    margin-bottom: calc($margin + 5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius;
    // transition: $transition;
    animation: $page-animation;
    cursor: pointer;

    &:hover {
        @include themed {
            box-shadow: t('box-shadow');
        }
    }

    .left-section {
        .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            // transition: $transition;
            animation: $pop-animation;
            height: calc($header-height + 10px);
            width: calc($header-height + 10px);

            @include themed {
                box-shadow: t("box-shadow");
            }

            i {
                font-size: 2em;
                color: $primary-color;

            }
        }

        .img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            // transition: $transition;
            // animation: $pop-animation;
            height: calc($header-height + 10px);
            width: calc($header-height + 10px);
            background: rgb(255, 255, 255);

            @include themed {
                box-shadow: t("box-shadow");
            }

            &#big {
                @include themed {
                    background-color: t("hero");
                }

                img {
                    border-radius: 100px;
                    // transition: $transition;
                    // animation: $pop-animation;
                    height: calc($header-height + 10px);
                    width: calc($header-height + 10px);
                    // background: rgb(255, 255, 255);
                    @include themed {
                        background-color: t("hero");
                    }
    
                }
            }

            img {
                border-radius: 100px;
                // transition: $transition;
                // animation: $pop-animation;
                height: calc($header-height - 15px);
                width: calc($header-height - 15px);
                background: rgb(255, 255, 255);
            }
        }
    }

    .right-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
            text-transform: capitalize;
            font-weight: $font-weight-regular;
            font-size: $title-font-size;
            color: $label-color;
            animation: $pop-animation;
        }

        .count {
            font-size: 1.5em;
            font-weight: $font-weight-bold;

            @include themed {
                color: t("text");
            }

            animation: $pop-animation;
        }
    }
}

#bg {
    @include themed {
        background-color: t('bg');
    }

}

#badge-icon {
    @include themed {
        background-color: t('bg');
    }

}