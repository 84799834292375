.guest {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: $border-radius;
        @include themed {
            background-color: t('hero');
            color: t('text');
          }
        width: 35%;
        margin-bottom: 10px;
        max-height: calc(100vh / 1.2);

        @media screen and (max-width: $large-screen) {
            width: 50%;
        }

        @media screen and (max-width: $medium-screen) {
            width: 80%;
        }

        @media screen and (max-width: $small-screen) {
            width: 90%;

            .left-align {
                text-align: right !important;
            }
        }
    }
}

