.appbar {
  top: 0;
  right: 0;
  z-index: 996;
  position: fixed;

  @include themed {
    background-color: t('bg');
  }

  width: calc(100% - $sidebar-width);
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // transition: $transition;

  // box-shadow: $box-shadow;
  .toolbar {
    display: flex;
    align-items: center;
    // transition: $transition;
    justify-content: space-between;
    padding: 15px calc($padding + 10px);

    .title {
      font-family: $font-family;
      margin-left: $space;
      font-size: $font-size;
      font-weight: $font-weight-semibold;

      @include themed {
        color: t('text');
      }

      text-transform: capitalize;
    }

    .material-icons-round {
      display: inline-block;
      position: relative;
      font-size: 24px;
      padding: 8px;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: rgba(170, 169, 169, .3);

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }

  }

  .right-section {
    gap: 20px;
    display: flex;
    align-items: center;
    position: relative;


    .theme-switcher-container {
      display: flex;
      align-items: center; // Center the theme switcher
      justify-content: center;

      button {
        padding: 5px !important;
        margin: 0 !important;
        min-width: 0 !important;
        background: none;
        border: none;
        cursor: pointer;

        .material-icons-round {
          font-size: 1.5rem;
          color: gray;
          /* Default color */
          transition: color 0.3s;
          /* Smooth color transition */

          &:hover {
            background: none;
            opacity: .8;
          }
        }
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // background-color: $primary-color; // green background color
      color: $light-color; // white text color
      text-transform: uppercase;
      font-size: $font-size !important;
      font-weight: $font-weight-semibold;
      cursor: pointer;

      // Add gradient and box-shadow for glowing effect
      background: linear-gradient(135deg, rgba(26, 115, 232, 1) 0%, rgba(26, 115, 232, 1) 100%);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      +.menu {
        position: absolute;
        top: 100%;
        right: 0;

        @include themed {
          color: t('text');
        }

        border-radius: 0;
        box-shadow: $box-shadow;
        margin-right: calc($margin + 4px);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 320px;
        box-sizing: border-box;
        /* include border in total width/height calculation */
        z-index: 10;
        min-width: $sidebar-width;

        @include themed {
          background-color: t('bg');
          border: t("divider");
        }

        .view-names {
          padding: 1rem;
          display: flex;
          flex-direction: column;
        }

        .title {
          font-size: $font-size;
          padding: 2rem 1rem .5rem;
          font-family: $font-family;

          @include themed {
            color: t('text');
          }

          font-weight: bold;
          position: relative;

          .material-icons-round {
            position: absolute;
            top: 100%;
            right: -4rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            // color: $text-color;

            &:hover {
              @include themed {
                background-color: t('bg');
                color: t("text");
              }
            }
          }
        }

        .avatari {
          display: flex;
          align-items: center;
          justify-content: center;
          height: calc($header-height + 15px);
          width: calc($header-height + 15px);
          border-radius: 50%;
          // background-color: $primary-color; // green background color
          text-transform: uppercase;
          font-size: 2em !important;
          color: $light-color;
          font-weight: $font-weight-semibold;
          cursor: pointer;

          background: linear-gradient(135deg, rgba(26, 115, 232, .5) 0%, rgba(26, 115, 232, .8) 100%);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

        }


        &::before {
          content: "";
          position: absolute;
          top: calc(30% + 1px);
          /* position just above avatar */
          left: 0;
          width: 100%;
          height: calc(70% - 1px);
          /* set height to be the same as avatar height plus border width */
          z-index: -1;

          @include themed {
            background-color: t('hero');
          }
        }

        .menu-item {
          text-align: start !important;
          padding-right: 6rem !important;
          height: 100%;
          // padding: 0;
          list-style: none;

          li {
            padding: 0;
            margin: 0;
            list-style: none;
            transition: $transition;

          }

          .item {
            margin-bottom: 10px;
            transition: $transition;

            .item-link {
              display: flex;
              align-items: center;
              font-size: $font-size;
              font-weight: $font-weight-semibold;
              transition: $transition;
              text-transform: capitalize;

              // color: $sidebar-link-color;
              @include themed {
                background-color: t('hero');
                color: t("text");
              }

              outline: none;
              border: none;

              i {
                font-size: $icon-font-size;
                margin-right: $margin;

                @include themed {
                  background-color: t('text');
                }
              }

              &:hover,
              &.active,
              &.active i {
                color: $primary-color;
              }

              &:hover i {
                color: $primary-color;
              }
            }

          }
        }
      }


    }

    .title {
      margin-left: 0rem;
      font-size: $font-size;
      font-weight: $font-weight-regular;
      color: $label-color;
      font-family: $font-family;
      // text-transform: lowercase;
    }
  }
}