button {
    outline: none;
    box-sizing: border-box;
    margin: 1rem 0 0 0;
    padding: 0 $button-padding;
    box-shadow: none;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    border: none;
    cursor: pointer;
    display: inline-block;
    min-width: 100px;
    height: $button-height;
    background-color: $primary-color;
    color: $light-color;
    border-radius: $button-border-radius ;
    transition: $transition;
    animation: $page-animation;

    &:hover {
        box-shadow: none;
        opacity: .9;
    }

    &.secondary {
        background-color: $background-color;
        color: $label-color;
    }

    &.disabled {
        background-color: $disabled-background-color;
        color: $disabled-text-color;
        cursor: not-allowed;
        box-shadow: none;
    }
}

.action-button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    // animation: $pop-animation;

    // @media screen and (max-width: $medium-screen) {
    //     justify-content: flex-end;
    // }

    .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 5px;
        border-radius: 50%;
        position: relative; // Needed for the glow effect


        &.disabled {
            background-color: #e0e0e0; // Adjust for disabled state
            color: #b0b0b0;
            cursor: not-allowed;
            box-shadow: none;
            animation: none; // Disable animation for disabled state
        }

        &:last-child {
            margin-right: 0;
        }

        i {
            font-size: calc($icon-font-size - .2rem);
        }

        color: $primary-color;

        // Primary button style
        &.primary {
            background-color: $primary-color;
            color: $light-color;
            box-shadow: 0 4px 15px rgba($primary-color, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba($primary-color, 0.6);
            }
        }

        // Error button style
        &.error {
            background-color: $error-color;
            color: $light-color;
            box-shadow: 0 4px 15px rgba($error-color, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba($error-color, 0.6);
            }
        }

        // Success button style
        &.success {
            background-color: $success-color;
            color: $light-color;
            box-shadow: 0 4px 15px rgba($success-color, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba($success-color, 0.6);
            }
        }

        // Info button style
        &.info {
            background-color: $info-color;
            color: $light-color;
            box-shadow: 0 4px 15px rgba($info-color, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba($info-color, 0.6);
            }
        }

        // Warning button style
        &.warning {
            background-color: $warn-color;
            color: $light-color;
            box-shadow: 0 4px 15px rgba($warn-color, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba($warn-color, 0.6);
            }
        }

        // Secondary button style
        &.secondary {
            color: $light-color;
            background-color: rgb(137, 155, 189);
            box-shadow: 0 4px 15px rgba(137, 155, 189, 0.4);
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 8px 20px rgba(137, 155, 189, 0.6);
            }
        }
    }

}

.floating-button {
    position: fixed;
    transition: $transition;
    animation: $page-animation;
    right: 25px;
    bottom: 25px;

    // &:hover {
    //     opacity: .8;
    // }

    a {
        color: $light-color;
        overflow: hidden;
        width: 56px;
        height: 56px;
        line-height: 40px;
        padding: 0;
        background-color: $primary-color;
        box-shadow: 0 0 0.625rem rgba($dark-color, 0.2);

        border-radius: 50%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            font-size: $icon-font-size;
        }
    }

    @media screen and (min-width: $medium-screen) {
        &.floating-button-menu {
            width: 0;
            display: none;
            left: 25px;
            bottom: 25px;
            z-index: 999;
        }


    }

    @media screen and (max-width: $medium-screen) {
        display: none;

        &.floating-button-menu {
            display: block;
            z-index: 999;
            right: 10px;
            bottom: 10px;
        }

        &.home {
            display: block;
            right: 25px;
            bottom: 25px;
            z-index: 999;
        }

    }
}



.exam-label {
    p {
        margin: 0;
        color: rgba($info-color, 1);
        font-size: $title-font-size;
        font-family: $font-family;
    }

    // align-items: center;
    // text-align: center;

    :where(span) {
        font-size: .9rem;
    }

    .exam-radio {
        font-family: $font-family;
        font-size: $font-size;
        // padding: 5px;
        margin-bottom: 1rem;

        span {
            padding: 0 4px;
        }

    }
}


.invoice-options {
    position: relative;

    button {
        animation: none;
        transition: none;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: 64px;
        outline: none;
        line-height: inherit;
        user-select: none;
        -webkit-appearance: none;
        overflow: visible;
        vertical-align: middle;

        color: $primary-color;
        margin: 0;
        border: 1px $primary-color solid;
        height: 38px;

        &:hover {
            background: rgba($background-color, .7);
        }

        @include themed {
            background-color: t('hero');
        }

    }

    .options {
        transition: $transition;
        z-index: 9;
        display: flex;
        margin-top: 2px;
        min-height: 30px;
        text-align: center;
        position: absolute;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px $primary-color solid;

        .item {
            border-bottom: 1px $primary-color solid;

            &:last-child {
                border-bottom: none;
            }
        }

        @include themed {
            background-color: t('bg');
        }
    }
}