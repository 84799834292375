.report {
    margin: $margin 0;
    height: 100vh;

    .paper {
        padding: $card-padding;
        font-family: $font-family;

        @include themed {
            color: t('text');
            background-color: t('hero');
            box-shadow: t('box-shadow');
            // border: t('divider');
        }

        .report-header {
            display: flex;
            justify-content: space-between;
            font-size: .8rem;
            align-items: center;
            text-align: center;
            width: 100%;

            @media only screen and (max-width: $small-screen) {
                font-size: .8rem;
            }

            .left-section {
                .logo {
                    height: 100%;

                    img {
                        height: 100%;
                        width: 150px;

                        @media only screen and (max-width: $small-screen) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }

            .center-section {
                text-align: center;
                align-items: center;

                @media only screen and (max-width: $small-screen) {
                    font-size: .5rem;
                }

                .name {
                    text-transform: uppercase;
                    font-weight: $font-weight-bold;
                    font-size: $title-font-size;

                    @include themed {
                        color: t('text');
                    }

                    @media only screen and (max-width: $small-screen) {
                        font-size: .7rem;
                    }
                }

                .area {
                    margin: 1.5px 0;
                    text-transform: capitalize;
                }

                .email {
                    margin: 1.5px 0;
                }

                .phone {
                    margin: 0.3rem;
                }

                .date {
                    font-size: 12px;
                    margin-bottom: .5rem;
                }
            }

            .right-section {
                .logo {
                    height: 100%;

                    img {
                        height: 100%;
                        width: 150px;

                        @media only screen and (max-width: $small-screen) {
                            width: 50px !important;
                            height: 50px !important;
                        }
                    }
                }

                .icon {
                    height: 100%;

                    img {
                        height: 100%;
                        width: 150px;

                        @media only screen and (max-width: $small-screen) {
                            width: 50px !important;
                            height: 50px !important;
                        }
                    }
                }
            }
        }
    }
}