// ANIMATIONS
$transition: all ease-in-out .5s;
$page-animation: scaleIn 0s;
$slide-in-animation: slideIn 0s;
$fade-in-animation: fadeIn 0s;
$rotate-animation: rotate;
$pop-animation: pop 0s ease-in-out;
$bell-shake-animation: bellShake 2.5s infinite;

//  SHADOW
//  $box-shadow: 0 0 0.625rem rgba($dark-color, 0.1);
$box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

// SHADOW CLASS
.shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

$text-shadow: 1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
    1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2),
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);

$text-shadow-light: 1px 1px 1px rgba(16, 16, 16, 0.1),
    1px 2px 1px rgba(16, 16, 16, 0.1),
    1px 3px 1px rgba(16, 16, 16, 0.1),
    1px 4px 1px rgba(16, 16, 16, 0.1),
    1px 5px 1px rgba(16, 16, 16, 0.1),
    1px 6px 1px rgba(16, 16, 16, 0.1),
    1px 7px 1px rgba(16, 16, 16, 0.1),
    1px 8px 1px rgba(16, 16, 16, 0.1),
    1px 9px 1px rgba(16, 16, 16, 0.1),
    1px 10px 1px rgba(16, 16, 16, 0.1),
    1px 18px 6px rgba(145, 145, 145,0.5),
    1px 22px 10px rgba(145, 145, 145, 0.2),
    1px 25px 35px rgba(145, 145, 145, 0.2),
    1px 30px 60px rgba(145, 145, 145,0.5);