.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    padding: $card-padding 0px;
    width: 100%;
    animation: $page-animation;
    margin-bottom: calc($margin + 5px);
    max-height: calc(100vh - 60px);

    ::-webkit-scrollbar {
        display: none;
    }

    &.image {
        padding: 0;
        max-height: 350px;

        @media screen and (max-width: $medium-screen) {
            max-height: 450px;
        }

        &:hover {
            transition: 0.5s;
            transform: translateY(-10px);
        }

        .img {
            height: 350px; // Set the desired fixed height for all images

            @media screen and (max-width: $medium-screen) {
                height: 450px;
            }

            img {
                width: 100%;
                height: 100%; // Set the desired fixed height for all images
                object-fit: cover; // Ensure images cover the space without distortion
                border-radius: inherit;
            }
        }

        // .detail {
        //     margin: 1rem;
        // }
    }

    &.list {
        max-height: calc(100vh - (60px * 2) - 20px);

        .card-content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: auto;
            animation: $slide-in-animation;
            padding: 0 $card-padding;

            .content {
                display: flex;
            }
        }

        @media #{$large-and-up} {
            .card-content {
                position: relative;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: auto;
                animation: $slide-in-animation;
                padding: 0 $card-padding;
                margin-top: $card-padding;
            }

        }

    }

    .card-title {
        @include themed {
            color: t('text');
        }

        font-size: $title-font-size;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        padding: 0 $card-padding 1rem $card-padding;
    }

    .card-content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 $card-padding 0 $card-padding;
        overflow-y: auto;
        overflow-x: auto;
        animation: $slide-in-animation;

        overflow: hidden;

        &:hover {
            /* or whatever height you want */
            overflow-y: auto;
        }


        &:hover::-webkit-scrollbar {
            display: block;
        }
    }

    .card-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $card-padding;
        width: 100% !important;

        .left-section {
            display: flex;
            flex-direction: row;
            align-items: center;

            input,
            button {
                margin: $no-margin;
            }

            button {
                min-width: 50px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: $margin !important;
            }
        }

        @media screen and (max-width: $medium-screen) {
            width: 100vw;
            display: inline-flex;
            flex-direction: column;
            padding: 0 $card-padding;

            .left-section {
                padding: $padding $padding 0 $padding;
                align-items: center;
                justify-content: center;
            }

            .right-section {
                padding: .5rem 0;
            }

        }
    }

    &.list {
        .card-filter {
            padding: 0 $card-padding;
        }

        @media screen and (max-width: $medium-screen) {
            margin: $no-margin;
            padding: $no-padding;
        }
    }

}

.card-content :where(#label) {
    display: flex;
    padding: 1rem;
    column-gap: .5rem;

    &:hover {
        color: $primary-color;
        background: $card-background-color;
    }

}


.card-subtitle {
    text-transform: capitalize;

    @include themed {
        color: t('text');
    }

    font-weight: $font-weight-semibold;
    font-size: $font-size;
    // padding: 0 .8rem 1rem;
    width: 100%;
    // height: 50px;
    padding: 0 $card-padding 1rem 0;

}