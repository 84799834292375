.table {
    width: 100%;
    position: relative;

    @include themed {
        background-color: t('hero') !important;
    }

    animation: $page-animation;

    .th {
        text-transform: capitalize;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        color: $light-color;
        background-color: $primary-color;
        position: sticky;
        top: 0;
        height: 3.75rem;
        padding: 0 $padding;
        text-align: center;
        z-index: 10;

        &.role-hd {
            .header-content {
                margin-left: 1rem;
                /* Adjust spacing between checkbox and title */
                display: flex;
                /* Ensure children are aligned in a row */
                align-items: center;
                /* Align items vertically */
            }

            .header-title {
                margin-left: 1rem;
                /* Adjust spacing between checkbox and title */
            }
        }
    }

    .tr {
        cursor: pointer;
        vertical-align: middle;
        animation: $slide-in-animation;

        @include themed {
            background-color: t('bg');
        }

        &:nth-child(even) {
            @include themed {
                background-color: t('even');
            }
        }

        &:hover {
            @include themed {
                background-color: t('t-hover');
            }
        }

        &#editable {
            @include themed {
                background-color: t('hero');
            }
        }
    }

    .td {
        font-size: $font-size;
        font-weight: $font-weight-regular;

        @include themed {
            color: t('sidebar-link-color');
        }

        ;
        height: 3.4375rem;
        padding: 0 $padding;
        text-align: center;

        img {
            height: 100%;
        }

        &.fee {
            align-items: center;
            text-align: center;

            input {
                @include themed {
                    background-color: t('hero');
                }
            }
        }

    }

    .tfoot {
        @include themed {
            border-top: t('divider');
        }
    }

    @media screen and (max-width: $medium-screen) {
        .thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .tr {
            display: block;
            margin-bottom: $margin;
            border: none;
        }

        .td {
            padding: $padding;

            @include themed {
                border: t('divider');
            }

            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $font-size;
            text-align: right !important;

            &:last-child {
                @include themed {
                    border-bottom: t('divider');
                }
            }

            &::before {
                content: attr(data-label);
                float: left !important;
                font-size: $font-size;
                font-weight: $font-weight-semibold;

                @include themed {
                    color: t('sidebar-link-color') !important;
                }

                ;
            }
        }
    }
}

.comment {
    height: calc($input-height + 50px) !important;
}

.td[contenteditable] {
    background-color: white;
    cursor: text;
}

.td {
    &#editable:focus {
        background-color: lightblue;
        cursor: auto;
    }
}

.tbody {
    overflow-x: hidden !important;
}

#indexed {
    z-index: 1;
}


.header-field {
    position: "relative";
    text-align: "center";
    padding: 0;

    .sub-divider {
        position: "absolute";
        left: 0;
        right: 0;
        top: 50%;
        border-top: "1px solid black";
    }
}

.tr.even {
    background-color: rgba($background-color, .8);
}


.striped-table {
    width: 100%;
    position: relative;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    // transition: $transition;
    animation: $page-animation;


    .striped-th {
        width: 100%;
        text-transform: capitalize;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        text-align: left;
        position: sticky;
        top: 0;
        height: $input-height;
        padding: 0 $padding;

        @include themed {
            background-color: t('even');
        }
    }

    .striped-tr {
        cursor: pointer;
        vertical-align: middle;
        animation: $slide-in-animation;

        &:nth-child(even) {
            @include themed {
                background-color: t('even');
            }

        }
    }

    .striped-td {
        font-size: $font-size;
        font-weight: $font-weight-regular;
        text-align: center;

        @include themed {
            color: t('sidebar-link-color') !important;
        }

        ;
        height: calc($input-height + 10px);
        padding: 0 $padding;
    }
}

.stream-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    height: 100%;
}

.stream-item {
    margin: 0 5px;
    /* Adjust margin as needed for spacing between stream items */
}

.streamline {
    margin-top: -.2rem;
}

.normal-table {
    width: 100%;
    position: relative;
    border-collapse: collapse;

    @include themed {
        background-color: t('hero');
    }


    @media only screen and (max-width: $small-screen) {
        font-size: .5rem;
    }

    .normal-th {
        top: 0;
        font-size: 14px;
        height: 3.75rem;
        text-transform: capitalize;
        font-weight: $font-weight-bold;

        @include themed {
            background-color: t('hero');
            border: t("divider");
            color: t("text");
        }


        @media only screen and (max-width: $small-screen) {
            font-size: .5rem;
        }
    }

    .normal-tr {
        cursor: pointer;
        vertical-align: middle;

        @include themed {
            border: t("divider");
        }

        text-transform: capitalize;
        border-radius: 0 !important;
    }

    .normal-td {
        font-size: 14px;
        text-align: center;

        @include themed {
            border: t("divider");
            color: t("text");
        }

        height: 3.75rem;
        border-radius: 0 !important;

        @media only screen and (max-width: $small-screen) {
            font-size: .5rem;
            height: 30px;

            img {
                height: 26px !important;
            }
        }

        img {
            height: 3.75rem;
        }

    }

    .normal-tfoot {
        display: table-row-group;
    }
}