.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $sidebar-width;
    height: 100vh;
    z-index: 998;
    // transition: $transition;
    padding: $padding;
    scrollbar-width: 0 !important;

    // box-shadow: $box-shadow;

    // border-right: 1px solid #dadce0;
    @include themed {
        background-color: t('side');
    }

    .toggle-sidebar-btn {
        position: absolute;
        right: 0;
        z-index: 10000;
        font-size: $icon-font-size;
        // background-color: $primary-color;
        // border-radius: 50% 0 0 50%;
        border-right: none;

        @include themed {
            color: t('text');
        }

        cursor: pointer;
        // transition: $transition;
        animation: $page-animation;
        top: 15px;
        line-height: normal;
    }

    .logo-container {
        height: 100px;

        @include themed {
            border-bottom: t('divider')
        }

        .logo {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                max-width: 80px;
                margin: 0;
            }

            span {
                font-size: $title-font-size;
                font-weight: $font-weight-bold;
                color: $sidebar-link-color;
                padding-bottom: $padding;
                // display: none;
            }
        }
    }

    .sidebar-nav {
        height: 100%;
        padding: 0;
        list-style: none;
        overflow-y: auto;
        padding-bottom: 100px;

        &::-webkit-scrollbar {
            display: none !important;
        }

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            // transition: $transition;

        }

        .nav-title {
            margin-bottom: 10px;
            font-weight: $font-weight-semibold;

            @include themed {
                color: t("sidebar-title");
            }
        }

        .nav-item {
            margin-bottom: 10px;
            // transition: $transition;
        }

        .nav-link {
            display: flex;
            align-items: center;
            font-size: $font-size;
            font-weight: $font-weight-regular;
            padding: 15px $padding;
            text-transform: capitalize;

            @include themed {
                background-color: t('side');
                color: t("sidebar-link-color");
            }

            outline: none;
            border: none;

            i {
                font-size: $icon-font-size;
                margin-right: $margin;

                @include themed {
                    color: t("sidebar-link-color");
                }
            }

            &:hover,
            &.active,
            &.active i,
            &.active span {
                color: $primary-color;
                transition: 0.5s;

                @include themed {
                    background-color: t('bg');
                }
            }

            &:active,
            &:active i,
            &:active span {
                color: $primary-color;

                @include themed {
                    background-color: t('bg');
                }
            }

            &:hover i {
                color: $primary-color;
            }
        }

        .nav-content {
            padding: 5px 0 0 0;
            margin: 0;
            list-style: none;
            display: none;

            @include themed {
                background-color: t('side');
            }

            max-height: 0;
            // transition: $transition;

            &.show {
                max-height: 100vh;
                display: block;
            }

            a {
                display: flex;
                align-items: center;
                font-size: calc($font-size - 1px);
                font-weight: $font-weight-regular;

                // color: $sidebar-link-color;
                @include themed {
                    color: t("sidebar-link-color");
                }

                // transition: $transition;
                padding: 5px 0 10px 35px;
                text-transform: capitalize;
                // transition: $transition;

                &:hover,
                &.active {
                    color: $primary-color;
                }
            }

            i {
                font-size: $icon-font-size;
                margin-right: 10px;
                line-height: 0;
            }
        }

    }
}