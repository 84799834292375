.lab-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    gap: 2rem;
    font-family: $font-family;

    @include themed {
        background-color: t('bg');
    }

    .content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 1rem;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-family: $font-family;



        @media screen and (min-width: 768px) {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            -moz-box-orient: horizontal;
            -moz-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            gap: 1.5rem;
        }


        .avatar {
            width: 8rem;
            height: 8rem;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            overflow: hidden;
            position: relative;

            @include themed {
                background-color: t('hero');
            }

            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;

            @media screen and (min-width: 768px) {
                width: 9.25rem;
                height: 9.25rem;
            }

            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: center;
                object-position: center;
            }
        }


        .info {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -moz-box-orient: vertical;
            -moz-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: .5rem;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;

            @media screen and (min-width: 768px) {
                -webkit-box-align: start;
                -webkit-align-items: flex-start;
                -moz-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                text-align: left;
            }

            .info-title {
                color: $primary-color;
                font-weight: $font-weight-bold;
                font-size: 2rem;
            }

            @media screen and (min-width: 768px) {
                .chip {
                    width: fit-content;
                }
            }

            .chip--white {
                @include themed {
                    background-color: t('hero');
                }

                color: #4C596D;
            }

            .chip--medium {
                font-size: .875rem;
            }

            .chip {
                padding: .25rem .75rem;
                -webkit-border-radius: 2rem;
                -moz-border-radius: 2rem;
                border-radius: 2rem;
                width: fit-content;
                min-width: 1.75rem;
                min-height: 1.75rem;
                text-transform: capitalize;
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -moz-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-flex-shrink: 0;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                font-weight: $font-weight-semibold;
            }
        }
    }

    .about-lab {
        gap: 1;

        .title {
            font-size: 1.2rem;
            font-family: $font-family;
            font-weight: $font-weight-regular;

            @include themed {
                color: t("text");
            }
        }

        .explanation {
            p {
                font-size: 1rem;
                line-height: 1.5rem;

                @include themed {
                    color: t("text");
                }
            }
        }
    }
}