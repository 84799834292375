.vertical-tabs {
    display: flex;
    flex-direction: row;
    transition: $transition;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .tabs {
        display: flex;
        flex-direction: column;
        // margin-right: 15px;
        width: $sidebar-width;
        transition: $transition;
        scrollbar-width: 0 !important;
        // scrollbar-color: $icon-color transparent;
        font-size: $font-size;
        font-weight: $font-weight-semibold;
        transition: $transition;
        text-transform: capitalize;

        @include themed {
            color: t("sidebar-link-color");
        }

        padding-bottom: $padding;
        margin-right: $margin;


        @media (max-width: 768px) {
            border-right: none;
            margin-right: 0;
            flex-direction: row;
            width: 100%;
            overflow-x: scroll;

            .tab {
                margin-bottom: 0 !important;
            }


        }

        .tab {
            padding: 15px 15px $padding;
            margin: 2px 0;
            cursor: pointer;
            // border-radius: $border-radius;

            &:hover {
                background-color: $primary-color;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                color: $light-color;
            }

            &.active {
                background-color: $primary-color;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                color: $light-color;
            }
        }
    }

    .tab-content {
        flex: 1;
    }
}

.horizontal-tabs {
    display: flex;
    flex-direction: column;
    // transition: $transition;
    position: relative;
    width: 100%;

    @include themed {
        background-color: t('hero');
    }

    .tabs {
        display: flex;
        position: sticky;
        top: 0;
        // background: #fff; // Ensure the background color matches your card's background
        font-size: $font-size;
        font-weight: $font-weight-semibold;
        // transition: $transition;
        text-transform: capitalize;

        @include themed {
            color: t("sidebar-link-color");
            background-color: t('hero');
        }

        padding-bottom: $padding;
        text-align: center;
        overflow-x: auto;
        width: 100%;
        z-index: 10;

        .tab {
            padding: $padding;
            margin:  2px 1px;
            cursor: pointer;
            width: 100%;

            &:hover {
                background-color: $primary-color;
                color: $light-color;
            }

            &.active {
                background-color: $primary-color;
                color: $light-color;
            }
        }
    }

    .tab-content {
        flex: 1;
    }
}