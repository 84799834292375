.show-on-print {
    display: none !important;
}

@media print {

    .hide-on-print {
        display: none !important;
    }

    .printer-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: black;
        height: 60vh;
        background-color: #FFFFFF !important;
    }

    @page {
        size: A4 portrait landscape;
        margin-bottom: 0;
        background-color: #FFFFFF;
    }

    .school-name {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    .list-name {
        font-size: 2rem;
    }

    .show-on-print {
        display: block !important;
    }

    html,
    body {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
        position: relative;
    }

    /* Page footer */
    #pageFooter {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: black;
        background-color: #FFFFFF;
        border-top: 1px solid black;
        padding: 10px 0;


    }

    .page-break {
        page-break-before: always;
    }

    .pageNum {
        counter-increment: page;
        content: counter(page);
        left: 0;
        top: 100%;
        white-space: nowrap;
        z-index: 20;
        -moz-border-radius: 5px;
        -moz-box-shadow: 0px 0px 4px #222;
        background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .domain {
        text-align: left;
    }

    .page-number {
        text-align: right;
    }

    .title {
        font-size: 1.5rem;
        color: black;
        font-weight: bold;
    }

    .table,
    .th,
    .tr,
    .td,
    .tbody {
        color: black !important;
        background-color: transparent !important;
        break-inside: avoid;
    }

    .th {
        height: auto;
        color: black !important;
        border: 1px solid black !important;
        break-inside: avoid !important;
    }

    .td {
        color: black !important;
        border: 1px solid black !important;
    }

    .tr {
        color: black !important;
        border: 1px solid black !important;
        break-inside: avoid !important;
        background-color: #FFFFFF !important;
        height: auto;
    }

    .tr:nth-child(even) {
        border: 1px solid black !important;
    }

    .card-content {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .table {
        color: black !important;
        border-collapse: collapse !important;
        break-inside: avoid !important;
    }

    * {
        background-color: #FFFFFF !important;
    }

    .timetable-view {
        width: 100%;
        height: 100vh;
        padding: 0 !important;
        background-color: #FFFFFF !important;
    }

    header,
    footer {
        display: none !important;
    }

    table,
    figure {
        break-inside: avoid;
    }

    a::after {
        content: " (" attr(href) ")";
    }
}