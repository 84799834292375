.graph-container {
    display: flex;
    flex-direction: column;

    @include themed {
        background-color: t('hero');
        color: t("text");
    }

    border-radius: $border-radius;
    text-align: center;
    margin-bottom: calc($margin + 5px);
    width: 100%;
    padding: $card-padding 0;

    .divider {
        @include themed {
            border-bottom: t('divider');
        }
    }

    .graphs-tabs {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;

        .tab {
            padding: 10px;
            cursor: pointer;
            margin-right: 5px;

            &.active {
                border-bottom: 3px solid $primary-color;
            }
        }
    }
}