header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2rem;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 10000;
    position: fixed;
    height: $header-height;
    box-shadow: 0px 0px 1px rgba(158, 158, 158, 0.5);
    font-family: $font-family;

    @include themed {
        color: t('text');
        background-color: t('bg');
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        text-align: center;
        height: 100%;

        .left-section {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;

            .title {
                font-family: $font-family;
                font-size: $title-font-size;
                font-weight: $font-weight-bold;
                cursor: pointer;
            }

            .logo {
                width: 50px;
                height: 50px;
                text-align: center;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .right-section {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;

            .theme-switcher-container {
                display: flex;
                align-items: center; // Center the theme switcher
                justify-content: center;

                button {
                    padding: 5px !important;
                    margin: 0 !important;
                    min-width: 0 !important;
                    background: none;
                    border: none;
                    cursor: pointer;
            
                    .material-icons-round {
                      font-size: 1.5rem;
                      color: gray;
                      /* Default color */
                      transition: color 0.3s;
                      /* Smooth color transition */
            
                      &:hover {
                        background: none;
                        opacity: .8;
                      }
                    }
                  }
            }

            .menu-icon {
                display: none;

                @media #{$medium-and-down} {
                    display: block;
                }
            }

            .login-btn {
                margin-left: 1rem;
                background: transparent;
                border: 2px solid $primary-color;
                padding: .3rem;
                border-radius: 0; // .4rem

                &:hover {
                    opacity: .8;
                }

                & a {
                    font-size: 14px;
                    font-weight: 300;
                    font-family: $font-family;

                    @include themed {
                        color: t('text');
                    }

                    text-decoration: none;
                    position: relative;
                    padding: .5rem;
                    margin: 0 .5rem;

                    &:link {
                        @include themed {
                            color: t('text');
                        }
                    }

                    &.active {
                        font-weight: 500;
                        transition: all .000001s ease-in-out;
                        color: $primary-color;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 2px;
                        }
                    }
                }
            }
        }


        nav {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            ul {
                display: flex;
                margin: 0 !important;
                list-style-type: none;
                padding: 0 !important;
                justify-content: space-between;

                li {

                    & a {
                        font-size: 14px;
                        font-weight: 200;
                        font-family: $font-family;

                        @include themed {
                            color: t('text');
                        }

                        text-decoration: none;
                        position: relative;
                        padding: .5rem;
                        margin: 0 1rem;

                        &:link {
                            @include themed {
                                color: t('text');
                            }
                        }

                        &:hover {
                            color: $primary-color;
                        }

                        &.active {
                            font-weight: 500;
                            transition: all .000001s ease-in-out;
                            color: $primary-color;

                            &::after {
                                content: '';
                                position: absolute;
                                left: 0;
                                width: 100%;
                                height: 2px;
                            }
                        }
                    }
                }
            }

        }
    }

}

@media #{$medium-and-down} {
    .header-container {
        .login-btn {
            display: none;
        }

        nav {
            position: fixed;
            top: -100vh;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start !important;
            text-align: left;
            transition: opacity 0.35s ease-out 0.15s;

            @include themed {
                background-image: t('bi');
            }

            background-size: 100%;
            background-repeat: no-repeat;

            &.responsive_nav {
                transform: translateY(100vh);

                ul {
                    flex-direction: column;
                    gap: 2rem;
                    text-align: left;
                    align-items: start;

                    li {
                        width: 100%;
                        height: 100%;
                        text-align: left;

                        a {
                            font-size: 2rem !important;
                            line-height: 2.25rem !important;
                            font-weight: 600;
                            font-family: $font-family;

                            @include themed {
                                color: t('text');
                            }

                            &.active {
                                font-weight: 600;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }

    }

}

@media #{large-and-up} {
    header {
        flex-direction: column;
    }

    nav {
        position: static;
        flex-direction: row;
    }

    .nav-close-btn {
        display: none;
    }
}