    .necta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;

    @include themed {
        background-color: t('hero');
        color: t('text');
    }

    padding: $card-padding 0px;
    width: 100%;
    animation: $page-animation;
    max-height: calc(100vh - 60px);

    ::-webkit-scrollbar {
        display: none;
    }

    .necta-header {
        h1 {
            color: $primary-color;
        }
    }

    .necta-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        position: relative;
        animation: $slide-in-animation;
        padding: 0 $card-padding;
        // margin-top: $card-padding;
    }

}