// _themes.scss
@use 'sass:map';

$themes: (
  dark: (bg: $background-dark,
    text: $text-dark,
    even:rgba($background-dark, .7),
    t-hover:rgba($background-dark, .5),
    title: rgba(255, 255, 255, .6),
    tooltip: rgb(0, 0, 0),
    // background: linear-gradient(to bottom, gray 50%, green 100%);
    bi:linear-gradient(to bottom, rgb(61, 61, 61) 50%, $primary-color 100%),
    bg2: rgb(36, 36, 36),
    side: $hero-dark,
    bgf: rgba(36, 36, 36, 0.7),
    hero:$hero-dark,
    divider:2px solid $background-dark,
    btn-border:1px solid rgba(255, 255, 255, 0.7),
    sidebar-link-color:rgba(255, 255, 255, 0.7),
    sidebar-title:rgba(255, 255, 255, 1),
    shadow: $text-shadow,
    disabled:$disabled-background-color,
    autofill:inset 0 0 0px 1000px $background-dark,
    tip:#beffbe,
    box-shadow:0 0 0.625rem rgba($light-color, 0.2),
    calender-btn-shadow:0 0 0.125rem rgba(#535353, .25),
    faded:rgba(#535353, .5),

  ),
  light: (bg: $background-light,
    text: $text-light,
    even:rgba($background-color, .7),
    t-hover:rgba($background-color, .5),
    tooltip: rgb(255, 255, 255),
    bi:linear-gradient(to bottom, gray 50%, $primary-color 100%),
    bg2: rgb(248, 249, 250),
    side: $hero-light,
    bgf: rgba($primary-color, 0.07),
    hero: $hero-light,
    title: rgba(0, 0, 0, .6),
    divider:2px solid $background-light,
    btn-border:1px solid rgb(212, 212, 212),
    sidebar-link-color:rgba(#343239, 1),
    sidebar-title:rgb(0, 0, 0),
    shadow:$text-shadow-light,
    disabled:rgba(#343239, .1),
    autofill:inset 0 0 0px 1000px $background-light,
    tip:#ddf8dd,
    box-shadow:0 0 0.625rem rgba($dark-color, 0.2),
    calender-btn-shadow: 0 0 0.125rem rgba(#000, .25),
    faded:$disabled-text-color,
  ),
);

@mixin themed {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: (
        ) !global;

      @each $key, $submap in $map {
        $value: map.get($map, $key
        );
      $theme-map: map.merge($theme-map,
          ($key: $value,
          )) !global;
    }

    @content;

    $theme-map: null !global;
  }
}
}

@function t($key) {
  @return map-get($theme-map, $key);
}