.left {
    float: left !important;
}

.right {
    float: right !important;
}

// block at the center
.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.fixed {
    position: fixed;
}

.style-none{
    list-style: none !important;
}

.sticky-right {
    right: 0;
}
.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.remember {
    display: flex;
    align-items: center;
}


.scheduler-container {
    height: 100vh;
    width: 100vw;
}


.flex {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.timetable-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-color;
    // margin-bottom: calc($margin + 5px);
    padding: $card-padding;
    flex-direction: column;
}

.timetable {
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 100%;
}

.timetable th,
.timetable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    text-transform: uppercase;
    background-color: $light-color;
    color: $text-color;
}

.timetable th {
    background-color: $background-color;
}

.timetable td input {
    width: 100%;
    border: none;
}

.timetable td textarea {
    width: 100%;
    border: none;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    color: $label-color;
    outline: none;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    margin: $input-margin 0px;
    padding: $input-padding;
    transition: $transition;
    background-color: $light-color !important;
    min-height: 50px !important;
    animation: $page-animation;
    cursor: pointer;
  
    &::placeholder {
      color: $label-color;
      font-size: $font-size;
      font-weight: $font-weight-regular;
    }
}