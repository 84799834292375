.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: $margin;
  // transition: $transition;

  .content {
    @include themed {
      background-color: t('bg');
    }

    line-height: $badge-height;
    height: $badge-height;
    margin-right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: $transition;
    border-radius: 0; //4px

    &:hover .link,
    &.active .link,
    &:hover .link {
      background-color: $primary-color;
      color: $light-color;
      border-radius: 0; // 4px

      i {
        color: $light-color;
      }
    }

    .disabled {
      cursor: not-allowed;

      i {
        color: rgba($primary-color, .4);
      }
    }

    .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 100%;
      font-size: $font-size;
      font-weight: $font-weight-semibold;
      color: $label-color;
      transition: $transition;

      i {
        color: $primary-color;
      }
    }

    @media screen and (max-width: $medium-screen) {

      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11) {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}