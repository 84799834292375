.features {
    margin-top: $header-height;
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: $font-family;

    @include themed {
        background-color: t('hero');

    }

    .hero {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 50vh;
        font-size: 4rem;
        text-align: center;
        font-family: $font-family;
        font-weight: $font-weight-bold;

        @include themed {
            color: t('text');
            background-color: t('bg');

        }

        text-shadow: -3px 2px 3px #999999;
        width: 100%;

        @media #{$small-and-down} {
            font-size: 3rem !important;
        }
    }

    .Waves_root__pQF2s {
        // margin-bottom: -2rem;
        overflow: hidden;
        width: 100%;

        .Waves_waves__VMmit {
            @include themed {
                background-color: t('hero');
                fill: t("bg");
            }

            width: 150%;
            height: calc(2.5rem * 2);
            display: block;
            filter: (10px 5px 5px rgba(0, 0, 0, .03));
        }
    }

    .feature {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 300px;
        justify-content: center;
        margin: 1rem 0;
        width: 100%;
        font-family: $font-family;

        @include themed {
            background-color: t('bg') !important;
        }

        border-radius: $border-radius;
        padding: $card-padding;
        text-align: center;

        &.no-border {

            &:hover {
                border: none;
            }

            .right-section {
                text-align: left;
                font-family: $font-family;

                .title {
                    text-align: left;
                }
            }
        }


        &:hover {
            transition: 0.5s;
            transform: translateY(-10px);
        }

        .icon {

            i {
                color: $primary-color;
                font-size: 4em;
            }
        }

        .right-section {
            text-align: center;
            font-family: $font-family;

            ul {
                padding: 0;

                li {
                    color: #6c7686;
                    margin-bottom: 0.4rem;
                    font-size: .9rem;
                }
            }

            .title {
                @include themed {
                    color: t('title');
                }

                font-size: 1.5rem;
                font-weight: 500;
                text-decoration: underline;
                text-align: center;
                font-family: $font-family;
            }

            .desc {
                color: rgba(0, 0, 0, .6);
                font-size: 14px;
                font-family: $font-family;

            }
        }

        .learn-more {
            font-family: $font-family;

            a {
                outline: none;
                box-sizing: border-box;
                margin: 1rem 0 0 0;
                padding: 0 $button-padding;
                box-shadow: none;
                font-size: $font-size;

                @include themed {
                    border: t('btn-border');
                }

                cursor: pointer;
                display: inline-block;
                min-width: 100px;
                border-radius: $button-border-radius ;
                animation: $page-animation;
                font-family: $font-family;
                font-weight: $font-weight-regular;

                &:hover {
                    opacity: .8;
                }
            }

        }

    }
}

.contact {
    width: 100%;

    .feature-section {
        @include themed {
            background-color: t('hero') !important;
        }

        &.third {
            @include themed {
                transition: all .5s ease;
                background-color: t('bg') !important;
            }
        
            min-height: 400px;
            text-align: center;
        
            h1 {
                @include themed {
                    color: t('title');
                }
        
                font-family: $font-family;
                font-size: 3rem !important;
        
                @media #{$small-and-down} {
                    font-size: 2rem !important;
                }
            }
        
        
        }
    }

    .form {
        width: 100%;
        padding: 1rem 0;
    }

    ul {
        list-style: none;

        li {
            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                font-family: $font-family;
            }
        }
    }
}

.location {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    text-align: center;
    font-family: $font-family;

    @include themed {
        color: t('text');
    }

    .material-icons-round {
        font-size: 2rem;
        padding: 5px;
        color: $primary-color;
    }
}


.about {
    @include themed {
        background-color: t('hero') !important;
    }

}

.third {
    @include themed {
        transition: all .5s ease;
        background-color: t('bg') !important;
    }

    min-height: 400px;
    text-align: center;

    h1 {
        @include themed {
            color: t('title');
        }

        font-family: $font-family;
        font-size: 3rem !important;

        @media #{$small-and-down} {
            font-size: 2rem !important;
        }
    }


}