html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
  font-size: $font-size;
  overflow-x: hidden;
  line-height: 1.47059;
  font-weight: $font-weight-light;
  letter-spacing: -0.022em;
  font-style: normal;
}


a {
  color: $link-color;
  text-decoration: none;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family;
}

#main {
  margin-top: calc($header-height + 10px);
  padding: 0px .5rem !important;
}

p {
  font-size: 1rem;
  font-family: $font-family;

  @include themed {
    color: t('text');
  }
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  @include themed {
    background-color: t('bgf');
  }

  ;
  border-radius: $border-radius;
}

::-webkit-scrollbar-track {
  @include themed {
    background-color: t('hero');
  }

  ;
  border-radius: $border-radius;
}

.advanced-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 100px;
  margin-top: 1rem;
  box-sizing: border-box;
  padding: $padding;
  justify-content: center;
  border-radius: $border-radius;
  animation: $page-animation;
  cursor: pointer;
  box-shadow: $box-shadow;
  font-family: $font-family;

  p {
    font-size: $font-size;
  }
  @include themed {
    background-color: t('bg');
  }


}

.hidden {
  display: none !important;
}

.guest-link {
  font-size: 16px;
  font-weight: 300;
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &.right-link {
    color: $primary-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* FlipBook.css */
.flip-book {
  height: 90vh; /* Full viewport height */
  // width: 100vw; /* Full viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide any overflow */
}

iframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove default border */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.badge {
  // padding: 6px;
  margin: 2px;
  width: 1.2rem;
  font-size: 1rem;
  color: white;
  text-align: center;
  align-items: center;

  .first {
      background-color: blue;
      border-radius: 0;
  }

  .second {
      background-color: orange;
      border-radius: 0;
  }

  .thirding {
      background-color: green !important;
      border-radius: 0;
  }

  .fourth {
      background-color: red;
      border-radius: 0;
  }
}